// @ts-nocheck
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/state'
import { Message } from 'element-ui'
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/interview/welcome'
    },
    {
      path: '/interview/welcome', //AI模拟面试首页   忽略名单
      name: 'welcome',
      component: () => import('@/views/interview/welcome')
    },
    {
      path: '/interview/be_being_interview', //面试答题页面   忽略名单
      name: 'beBeingInterview',
      component: () => import('@/views/interview/beBeingInterview')
    },
    {
      path: '/interview/view_the_report', //查看报告页面 忽略名单
      name: 'viewTheReport',
      component: () => import('@/views//interview/viewTheReport')
    },
    {
      path: '/interview/report_list', //扫码登录面试报告列表页面  忽略名单
      name: 'reportList',
      component: () => import('@/views/interview/reportList')
    },
    {
      path: '/interview/tourist_report', //游客模式的学生面试报告页面 忽略名单
      name: 'touristReport',
      component: () => import('@/views/interview/touristReport')
    },
    {
      path: '/interview/record_list', //面试岗位列表 忽略名单
      name: 'recordList',
      component: () => import('@/views/interview/recordList')
    },
    {
      path: '/interview/pc_report', //AI模拟面试pc端报告页 忽略名单
      name: 'pcReport',
      component: () => import('@/views/interview/pcReport/index')
    },
    {
      path: '/resume/login', //简历诊断登录页面
      name: 'login',
      component: () => import('@/views/resume/login/index')
    },
    {
      path: '/resume/index', //简历诊断主页
      name: 'index',
      component: () => import('@/views/resume/index')
    },
    {
      path: '/resume/edit', //编辑简历页面
      name: 'edit',
      component: () => import('@/views/resume/edit/index')
    },
    {
      path: '/resume/diagnosis', //简历诊断首页
      name: 'diagnosis',
      component: () => import('@/views/resume/diagnosis')
    },
    {
      path: '/resume/pc_report', //简历诊断PC端报告页
      name: 'resumePcReport',
      component: () => import('@/views/resume/pcReport')
    },
    {
      path: '/resume/pdfPreview', //预览下载简历pdf页面
      name: 'pdfPreview',
      component: () => import('@/views/resume/pdfExport/pdfPreview/index')
    },
    {
      path: '/resume/resume_template', //预览下载模板简历word页面
      name: 'resumeTemplate',
      component: () => import('@/views/resume/resumeTemplate')
    },
    {
      path: '/employmen/analysis', //专业就业前景分析报告页
      name: 'analysis',
      component: () => import('@/views/employmen/analysis')
    },
    {
      path: '/employmen/pc_report', //专业就业前景分析页面  忽略名单
      name: 'employmenPcReport',
      component: () => import('@/views/employmen/pcReport/index')
    }
  ]
})

// 路由前置守卫
// 忽略名单
router.beforeEach((to, from, next) => {
  const token = store.token
  // 如果数组存在interview说明是忽略名单
  const losePath = to.path.split('/')
  if (losePath.includes('interview') || losePath.includes('employmen')) {
    next()
  } else if (losePath.includes('resume')) {
    if (token) {
      next()
    } else {
      if (to.path === '/resume/login') {
        next()
      } else {
		if (JSON.stringify(to.query) !== '{}' && to.query.token) {
		  next()
		} else {
		  Message.error('请先登录哦！')
		  next({ path: '/resume/login', query: { go: 1 } })
		}
      }
    }
  }
})

// 监听路由变化 进行动态的设置标题
router.afterEach(to => {
  const pageTitle = getPageTitle(to)
  document.title = pageTitle
})

// 通过路由获取页面标题
function getPageTitle(route) {
  let defaultTitle = null
  const losePath = route.path.split('/')
  if (losePath.includes('interview')) {
    defaultTitle = 'AI面试'
  } else if (losePath.includes('resume')) {
    defaultTitle = '简历诊断'
  } else if (losePath.includes('employmen')) {
    defaultTitle = '就业前景分析'
  }
  return defaultTitle
}

export default router
