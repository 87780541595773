<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import store from '@/store'
import { mapMutations } from 'vuex'

const token1 =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZGVhcGkuY3psa3MuY29tIiwiYXVkIjoiaHR0cDovL2RlYXBpLmN6bGtzLmNvbS92MS9sb2dpbiIsImp0aSI6InR6a2oiLCJpYXQiOjE2OTU3MjU3ODIuNDQwMDEyLCJleHAiOjE2OTU3MjkzODIuNDQwMDEyLCJ1aWQiOiIxNjk1NzI1NzY0MDAwNDcyIn0.9ETNV2QJxfG9ve7XVjtgvwj-UDqTSDKuxFEUAy6c0XQ'
if (token1) {
  store.dispatch('getUserInfo')
  store.commit('SET_TOKEN', token1)
  sessionStorage.setItem('token', token1)
}

// const token = sessionStorage.getItem('token')
// if (token) {
//   store.commit(token)
//   store.dispatch('getUserInfo')
// }

export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    ...mapMutations({
      setRouterPath: 'SET_ROUTER_PATH'
    }),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  watch: {
    $route(to) {
      this.setRouterPath(to.path)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
