import * as types from './mutation-types'
const mutations = {
  [types.SET_CHANNEL](state, data) {
    state.channel = data
  },
  [types.SET_TOKEN](state, data) {
    state.token = data
  },
  [types.SET_INTERVIEWTOKEN](state, data) {
    state.interviewToken = data
  },
  [types.SET_USERINFO](state, data) {
    //获取最新的用户信息
    state.userInfo = data
    sessionStorage.setItem('userInfo', JSON.stringify(data))
  },
  [types.SET_RESUMEANALYSISUSERINFO](state, data) {
    //获取用户上传简历解析的信息
    state.resumeAnalysisUserInfo = data
  },
  [types.REMOVER_CONTENTS](state, data) {
    //清除用户信息
    state.userInfo = null
    state.token = null
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('userInfo')
    sessionStorage.removeItem('resume_title')
    sessionStorage.removeItem('resumeFile')
  },
  [types.SET_ROUTER_PATH](state, data) {
    state.routerPath = data
  },
  [types.SET_CURRENT_RESUME_EDIT_ID](state, data) {
    //当前用户简历id
    state.currentResumeEditId = data
  },
  [types.SET_CURRENT_RESUME_INFO](state, data) {
    // 当前用户简历信息
    state.currentResumeInfo = data
  }
}

export default mutations
